<template>
  <div id="userLoginPage">
    <h2 style="margin-bottom: 16px">用户登录</h2>
    <div class="form-container">
      <a-form
        :model="form"
        :style="{ width: '500px', margin: '0 auto', textAlign: 'center' }"
        label-align="left"
        auto-label-width
        @submit="handleSubmit"
      >
        <a-form-item field="userAccount" label="账号">
          <a-input
            :style="{ width: '400px' }"
            v-model="form.userAccount"
            placeholder="请输入账号"
          />
        </a-form-item>
        <a-form-item
          field="userPassword"
          tooltip="密码不小于 8 位"
          label="密码"
        >
          <a-input-password
            :style="{ width: '400px' }"
            v-model="form.userPassword"
            placeholder="请输入密码"
          />
        </a-form-item>
        <a-form-item>
          <div
            style="
              display: flex;
              width: 400px;
              align-items: center;
              justify-content: space-between;
            "
          >
            <a-button type="primary" html-type="submit" style="width: 120px">
              登录
            </a-button>
            <a-link href="/user/register">新用户注册</a-link>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import API from "@/api";
import { userLogin } from "@/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";

const loginUserStore = useLoginUserStore();
const router = useRouter();

const form = reactive({
  userAccount: "",
  userPassword: "",
} as API.UserLoginRequest);

/**
 * 提交
 */
const handleSubmit = async () => {
  const res = await userLogin(form);
  if (res.data.code === 0) {
    await loginUserStore.fetchLoginUser();
    message.success("登录成功");
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("登录失败，" + res.data.message);
  }
};
</script>

<style scoped>
.form-container {
  display: grid;
  place-items: center;
}
</style>
